const regexClassSelector = (
	selector,
	expression,
	all = false,
	parent = document
) => {
	const elements = [...parent.querySelectorAll(selector)];
	const regex = new RegExp(expression);

	if (all) {
		return elements.filter((element) => element.className.match(regex));
	} else {
		return elements.find((element) => element.className.match(regex));
	}
};

class _VOLGTETranslateLinkSet {
	constructor(
		defaultLanguage,
		languages,
		container,
		select,
		frame,
		resetOptionText
	) {
		if (!container) return;

		this.iframe = frame;
		this.select = select;
		this.languages = languages;
		this.defaultLanguage = defaultLanguage;
		this.links = [];
		this.isDefaultLanguagePrepended = false;
		this.languageContainer = container;
		this.linkList = document.createElement("ul");
		this.currentLanguage = this.defaultLanguage;
		this.resetOptionText = resetOptionText;

		this.languageContainer.appendChild(this.linkList);
		this.setGoogleTranslateLinks();

		/* const observer = new MutationObserver((mutationsList) => {
         for (const mutation of mutationsList) {
            if (
               mutation.type === 'attributes' &&
               mutation.target.classList.contains('goog-te-menu2-item-selected') &&
               mutation.target.value.length > 0
            ) {
               this.selectLanguage(mutation.target.value);
            }
         }
      });

      observer.observe(this.iframe.contentDocument, {
         attributes: true,
         childList: false,
         subtree: true,
      }); */
	}

	selectLanguage(language) {
		this.links.forEach((link) => {
			link.classList.toggle(
				"googleTranslateActiveLanguage",
				link.dataset.value === language
			);
		});

		this.currentLanguage = language;
	}

	setGoogleTranslateLinks() {
		setTimeout(() => {
			let t = null;

			if (
				typeof this.iframe.contentDocument != "undefined" &&
				this.iframe.contentDocument.getElementsByTagName("table")[0]
			) {
				this.prependDefaultLanguageLink();

				t = [...this.iframe.contentDocument.querySelectorAll("table td a")];

				t.forEach((link) => {
					const value = link.value;

					if (this.languages.includes(value)) {
						const cl = document.createElement("a");
						cl.href = "javascript:void(0)";
						cl.className = "language-" + value;
						cl.textContent = link.querySelector(".text").textContent;
						this.links.push(cl);

						cl.referenceToElm = link;
						cl.dataset.value = link.value;
						cl.addEventListener("click", () => {
							cl.referenceToElm.click();
							//this.selectLanguage(cl.dataset.value);
						});

						if (this.currentLanguage === value) {
							this.selectLanguage(value);
						}

						const li = document.createElement("li");
						li.appendChild(cl);
						this.linkList.appendChild(li);
					}
				});
			}
		}, 1000);
	}

	prependDefaultLanguageLink() {
		if (this.isDefaultLanguagePrepended) return;

		const item = document.createElement("li");
		const link = document.createElement("a");

		link.className = "language-" + this.defaultLanguage;
		link.dataset.value = this.defaultLanguage;
		link.textContent = this.resetOptionText;
		link.href = "javascript:void(0)";

		if (this.currentLanguage === this.defaultLanguage) {
			link.classList.add("googleTranslateActiveLanguage");
		}

		link.addEventListener("click", () => {
			const GTIframeDocument = document.getElementById(":2.container");

			if (GTIframeDocument) {
				GTIframeDocument.contentWindow.document
					.getElementById(":2.restore")
					.click();

				this.links.forEach((savedLink) => {
					savedLink.classList.remove("googleTranslateActiveLanguage");
				});

				link.classList.add("googleTranslateActiveLanguage");
			}
		});

		this.links.push(link);
		item.appendChild(link);
		this.linkList.insertAdjacentElement("afterbegin", item);
		this.isDefaultLanguagePrepended = true;
	}
}

class VOLGTE {
	constructor(elementID, options) {
		this.element = document.getElementById(elementID);

		if (!this.element) return;

		this.defaultLangOptionPrepended = false;
		this.checkInterval;
		this.linkFrame = null;
		this.linkSet = null;
		this.bannerFrame = null;
		this.select = null;
		this.banner = null;
		this.callback;
		this.defaults = {
			language: "cs",
			languages: null,
			optionDisplayLanguageCode: false,
			resetOptionValue: "původní jazyk",
			selectWidgetLabel: "Widget překladu jazyků",
			languageSwitchCallback: null,
		};

		this.options = { ...this.defaults, ...options };

		this.language = this.options.language;
		this.languages = this.options.languages;
		this.selectedLanguage = document.documentElement.lang;
		this.element.dataset.selectedLanguage = this.selectedLanguage;
		this.createBanner();

		this.languageSelectEvent = null;

		const observer = new MutationObserver((mutationList, observer) => {
			for (const mutation of mutationList) {
				if (
					mutation.type === "attributes" &&
					mutation.attributeName === "lang"
				) {
					const oldLang = this.selectedLanguage;
					this.selectedLanguage = mutation.target.lang;

					if (this.selectedLanguage === this.language) {
						document.documentElement.classList.remove(
							"gte-attr-banner-visible"
						);
					} else {
						document.documentElement.classList.add(
							"gte-attr-banner-visible"
						);
					}

					//console.log('this.linkSet', this.linkSet);
					if (this.linkSet) {
						this.linkSet.selectLanguage(this.selectedLanguage);
					}

					if (this.languageSelectEvent) {
						this.languageSelectEvent(oldLang, this.selectedLanguage);
					}

					if (this.select) {
						this.select.value = this.selectedLanguage;
					}
				}
			}
		});

		observer.observe(document.documentElement, {
			attributes: true,
			childList: false,
			subtree: false,
		});
	}

	init(callback = null) {
		if (callback) {
			this.callback = callback;
		}
		return () => {
			this._initElement();
		};
	}

	registerEvent(type, callback) {
		if (type === "languageselect") {
			this.languageSelectEvent = callback;
		} else {
			console.warn(`Event type ${type} is not supported.`);
		}
	}

	createBanner() {
		const banner = document.createElement("div");
		const attrLink = document.createElement("a");
		this.banner = banner;
		attrLink.href = "https://translate.google.com/";
		attrLink.title = "Powered by Google Translate";
		attrLink.target = "_blank";

		const style = document.createElement("style");
		banner.className = "gte-attribution-banner skiptranslate";
		banner.appendChild(attrLink);

		style.textContent = `
         #goog-gt-tt,
         #goog-gt-tt + div > object,
         iframe.goog-te-menu-frame,
         iframe[id^=":"],
         iframe.goog-te-banner-frame {
            display: none !important;
         }

         .gte-attribution-banner {
            position: absolute;
            left: 0;
            top: -40px;
            right: 0;
            z-index: 90;
            box-sizing: border-box;
            background: white url(/ASPINCLUDE/vismoWeb5/html/images/gte-attr-color-regular.svg) no-repeat 50% 50%;
            box-shadow: 0 2px 10px rgba(0,0,0,0.12);
            height: 40px;
            display: none;
         }

         .gte-attribution-banner a,
         .gte-attribution-banner a:link,
         .gte-attribution-banner a:visited,
         .gte-attribution-banner a:hover,
         .gte-attribution-banner a:focus,
         .gte-attribution-banner a:active {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 40px;
            text-decoration: none;
            color: inherit;
         }

         .gte-attr-banner-visible body {
            position: relative;
            top: 40px !important;
         }

         .gte-attr-banner-visible .gte-attribution-banner {
            display: flex;
         }
      `;

		document.head.appendChild(style);
		document.body.insertAdjacentElement("afterbegin", banner);
	}

	createSelect() {
		const select = document.createElement("select");
		this.select = select;
		select.className = "goog-te-select skiptranslate";
		select.id = "google_translate_element_volgte_select";
		select.setAttribute("aria-label", this.options.selectWidgetLabel);
		const resetOption = document.createElement("option");

		resetOption.className = "skiptranslate";
		resetOption.value = this.language;
		resetOption.textContent = this.options.optionDisplayLanguageCode
			? this.language.toUpperCase()
			: this.options.resetOptionValue;
		select.appendChild(resetOption);

		this.links.forEach((link) => {
			const option = document.createElement("option");

			if (link.value.length > 0) {
				option.value = link.value;
				option.textContent = this.options.optionDisplayLanguageCode
					? link.value.toUpperCase()
					: link.querySelector(".text").textContent;
				select.appendChild(option);
			}
		});

		select.addEventListener("change", (event) => {
			//console.log(event);
			//console.log('selected', select.value);
			if (select.value === this.language) {
				const GTIframeDocument = regexClassSelector(
					"iframe.skiptranslate",
					"([a-zA-Z0-9]{4,6}-[a-zA-Z0-9]{4,6}-[a-zA-Z0-9]{4,6}-[a-zA-Z0-9]{4,6})"
				);
				//console.log(`GTIframeDocument`, GTIframeDocument);
				if (GTIframeDocument) {
					const restoreButton =
						GTIframeDocument.contentWindow.document.querySelector(
							'button[id*="restore"]'
						);

					if (restoreButton && select.value === this.language) {
						restoreButton.click();
						if (
							typeof this.options.languageSwitchCallback === "function"
						) {
							this.options.languageSwitchCallback(this.language);
						}
					} else {
						console.warn("Restore button not found");
					}
				}
			}
			//console.log(this.linkFrame);
			if (this.linkFrame) {
				const links = [
					...this.linkFrame.contentDocument.querySelectorAll("table td a"),
				];
				//console.log(links);
				const link = links.find((el) => el.value === select.value);

				if (link) {
					link.click();
					if (typeof this.options.languageSwitchCallback === "function") {
						this.options.languageSwitchCallback(link.value);
					}
				}
			}

			//const oldLang = this.selectedLanguage;
			//select.value = this.selectedLanguage;
			//
			//if (this.languageSelectEvent) {
			//   this.languageSelectEvent(oldLang, this.selectedLanguage);
			//}

			//this.element.dataset.selectedLanguage = select.value;
		});

		//const currentLink = this.linkFrame.contentDocument.querySelector('table a.goog-te-menu2-item-selected');
		const currentLink = regexClassSelector(
			"table a",
			"([a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6})",
			false,
			this.linkFrame.contentDocument
		);

		if (currentLink && currentLink.value.length > 0) {
			select.value = currentLink.value;
		} else {
			select.value = this.selectedLanguage;
		}

		this.element.appendChild(select);

		if (this.callback) {
			this.callback();
		}

		this.element.classList.add("ready");
	}

	_initElement() {
		const hr = document.createElement("hr");
		const heading = document.createElement("h2");

		hr.className = "cist";
		heading.className = "cist";
		heading.appendChild(document.createTextNode("Google Translator"));
		this.element.appendChild(heading);
		this.links = [];

		const config = {
			pageLanguage: this.language,
			layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
		};

		if (this.languages) {
			config.includedLanguages = this.languages;
		}

		new google.translate.TranslateElement(config, this.element.id);
		this.selectedLanguage = document.documentElement.lang;

		//console.log(this.selectedLanguage);
		this.checkInterval = setInterval(() => {
			//const iframe = document.querySelector('iframe.skiptranslate');
			const iframe = regexClassSelector(
				"iframe.skiptranslate",
				"([a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6})"
			);
			//console.log(iframe);
			if (iframe) {
				const links = [
					...iframe.contentDocument.querySelectorAll("table td a"),
				];

				if (links.length > 0) {
					this.linkFrame = iframe;
					this.links = links;
					clearInterval(this.checkInterval);
					this.createSelect();
					const gadget = this.element.querySelector(
						".goog-te-gadget-simple"
					);

					if (gadget) {
						gadget.style.display = "none";
					}

					this.element.appendChild(hr);
				}
			}
		}, 1000);
	}

	insertTranslateLinks(languages, container) {
		this.linkSet = new _VOLGTETranslateLinkSet(
			this.language,
			languages,
			container,
			this.select,
			this.linkFrame,
			this.options.resetOptionValue
		);
	}
}
